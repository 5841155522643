<template>
  <div class="addProduct">
    <div class="addProductTitle">
      <div class="myProduct">
        <p>我的商品</p>
      </div>
      <div class="addProduct">
        <el-button type="danger" @click="showAddDialog()">发布商品</el-button>
      </div>
    </div>
    <div class="productList">
      <el-table :data="goodsDatas">
        <el-table-column prop="name" label="商品分类"></el-table-column>
        <el-table-column prop="price" label="价格"></el-table-column>
        <el-table-column prop="quantity" label="数量"></el-table-column>
        <el-table-column prop="meterageUnit" label="计量单位"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button @click="showDetails(scope.row)" type="danger" size="small">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog v-dialogDrag title="发布商品" :visible.sync="dialogVisible" width="600px">
      <div class="addProductForm">
        <el-form ref="form" :rules="rules" :model="form" label-width="100px">
          <el-form-item label="商品编码" prop="code">
            <el-input v-model="form.code"></el-input>
          </el-form-item>
          <el-form-item label="商品图片" prop="image">
            <el-upload
              class="avatar-uploader"
              :action="actionUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :data="postData"
            >
              <img v-if="form.image" :src="form.image" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="市场价" prop="marketPrice">
            <el-input v-model="form.marketPrice"></el-input>
          </el-form-item>
          <el-form-item label="计量单位" prop="meterageUnit">
            <el-input v-model="form.meterageUnit"></el-input>
          </el-form-item>
          <el-form-item label="商品分类" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="商品备注" prop="note">
            <el-input v-model="form.note"></el-input>
          </el-form-item>
          <el-form-item label="产地" prop="origin">
            <el-input v-model="form.origin"></el-input>
          </el-form-item>
          <el-form-item label="单价" prop="price">
            <el-input v-model="form.price"></el-input>
          </el-form-item>
          <el-form-item label="初始仓数量" prop="quantity">
            <el-input v-model="form.quantity"></el-input>
          </el-form-item>
          <el-form-item label="资源渠道" prop="resourceChannel">
            <el-input v-model="form.resourceChannel"></el-input>
          </el-form-item>
          <el-form-item label="仓库" prop="warehouse">
            <el-input v-model="form.warehouse"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmAddProduct()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag title="查看商品" :visible.sync="goodsItemdialogVisible" width="600px">
      <div class="addProductForm">
        <el-form ref="goodsItem" :model="goodsItem" label-width="100px">
          <el-form-item label="商品编码" prop="code">
            <el-input v-model="goodsItem.code" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="商品图片" prop="image">
            <el-upload
              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :disabled="true"
            >
              <img v-if="goodsItem.image" :src="goodsItem.image" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="市场价" prop="marketPrice">
            <el-input v-model="goodsItem.marketPrice" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="计量单位" prop="meterageUnit">
            <el-input v-model="goodsItem.meterageUnit" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="商品分类" prop="name">
            <el-input v-model="goodsItem.name" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="商品备注" prop="note">
            <el-input v-model="goodsItem.note" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="产地" prop="origin">
            <el-input v-model="goodsItem.origin" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="单价" prop="price">
            <el-input v-model="goodsItem.price" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="初始仓数量" prop="quantity">
            <el-input v-model="goodsItem.quantity" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="资源渠道" prop="resourceChannel">
            <el-input v-model="goodsItem.resourceChannel" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="仓库" prop="warehouse">
            <el-input v-model="goodsItem.warehouse" :disabled="true"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      goodsItemdialogVisible: false,
      goodsDatas: [],
      form: {
        code: null,
        image: null,
        marketPrice: null,
        meterageUnit: null,
        name: null,
        note: null,
        origin: null,
        price: null,
        quantity: null,
        resourceChannel: null,
        warehouse: null
      },
      rules: {
        code: [
          { required: true, message: "请输入商品编码", trigger: "change" }
        ],
        image: [
          { required: true, message: "请上传商品图片", trigger: "change" }
        ],
        marketPrice: [
          { required: true, message: "请输入市场价", trigger: "change" }
        ],
        meterageUnit: [
          { required: true, message: "请输入计量单位", trigger: "change" }
        ],
        name: [
          { required: true, message: "请输入商品分类", trigger: "change" }
        ],
        note: [
          { required: true, message: "请输入商品备注", trigger: "change" }
        ],
        origin: [{ required: true, message: "请输入产地", trigger: "change" }],
        price: [{ required: true, message: "请输入单价", trigger: "change" }],
        quantity: [
          { required: true, message: "请输入初始仓数量", trigger: "change" }
        ],
        resourceChannel: [
          { required: true, message: "请输入资源渠道", trigger: "change" }
        ],
        warehouse: [
          { required: true, message: "请输入仓库", trigger: "change" }
        ]
      },
      goodsItem: {
        code: null,
        image: null,
        marketPrice: null,
        meterageUnit: null,
        name: null,
        note: null,
        origin: null,
        price: null,
        quantity: null
      },
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null
      }
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.getProductDatas();
  },
  methods: {
    getProductDatas() {
      protocolFwd.param_selectCommodity.param.firmId = this.sessionInfoGetter.firmId;
      http.postFront(protocolFwd.param_selectCommodity).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.goodsDatas = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    showAddDialog() {
      this.dialogVisible = true;
    },
    handleAvatarSuccess(res, file) {
      this.form.image = res.value;
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    showDetails(item) {
      this.goodsItemdialogVisible = true;
      this.goodsItem = item;
    },
    confirmAddProduct() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_addCommodity.param = this.form;
          http.postFront(protocolFwd.param_addCommodity).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.dialogVisible = false;
              this.getProductDatas();
              this.$EL_MESSAGE("提交成功！待后台审核成功后方可上市");
              this.$refs["form"].resetFields();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.addProduct {
  padding: 0 15px;
}
.addProductTitle {
  overflow: hidden;
}
.addProductTitle .myProduct {
  float: left;
  line-height: 40px;
}
.addProductTitle .myProduct p {
  font-size: 16px;
}
.addProductTitle .addProduct {
  float: right;
}
.productList {
  margin-top: 20px;
}
/* 头像 开始 */
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.submitIdentification {
  text-align: center;
}
/* 头像 结束 */
</style>
